* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  font-size: 16px;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
}

a {
  text-decoration: none;
}

p,
h6,
h5,
h4,
h3,
h2,
h1,
ul,
li {
  margin: 0;
  padding: 0;
}

.route-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

/* suppress focus ring on form controls for mouse users */
[data-whatintent='mouse'] *:focus {
    outline: none !important;
    box-shadow: none!important;
}

/* React Datepicker overrides */
/* Date picker */

.react-datepicker {
  font-family: PitchSans !important;
  border-radius: 0 !important;
  border-color: #d8d8d8 !important;
}

.react-datepicker__header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-color: #d8d8d8 !important;
  background-color: #f6f4f4 !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day:hover {
  border-radius: 0 !important;
}

.react-datepicker__day.react-datepicker__day--selected {
  background-color: #e19633 !important;
  border-radius: 0 !important;
}

/* Time picker */

.react-datepicker__time-container {
  width: 85px !important; 
}

.react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__time-list-item {
  white-space: nowrap;
  width: 100% !important;
}

.react-datepicker__time-list-item--selected {
  background-color: #e19633 !important;
  border-radius: 0 !important;
}

/* Checkout Tip Selector */
:root {
  --gold: #c87e1e;
  --font: PitchSans;
  --black: #231f20;
  --grey: #d8d8d8;
}

.tip-selector {
  margin-top: 40px;
  padding: 33px 0 40px 0;
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  color: var(--black);
}

.tip-selector__title {
  font-family: var(--font);
  color: var(--black);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 23px;
}

.tip-selector__row {
  display: flex;
}

.tip-selector__row:first-of-type {
  margin-bottom: 16px;
}

.tip-selector__field-wrap {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.tip-selector__radio {
  opacity: 0;
  position: fixed;
  width: 0;
}

.tip-selector__radio:checked + .tip-selector__radio-label {
  background-color: var(--gold);
}

.tip-selector__radio-label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 34px;
  border: 1px solid var(--gold);
  font-family: var(--font);
  font-size: 14px;
  line-height: 16px;
}

.tip-selector__number-input {
  width: 100%;
  font-size: 16px;
  height: 34px;
  border: 1px solid var(--gold);
  border-left: 0;
  color: var(--black);
  border-radius: 0px 2px 2px 0px;
  font-family: var(--font);
  font-size: 14px;
  line-height: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.tip-selector__input-dollar:before {
  position: absolute;
  top: 10px;
  content: '$';
  left: 5px;
  font-family: var(--font);
  font-size: 14px;
}

.tip-selector__number-input::-webkit-inner-spin-button,
.tip-selector__number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.tip-selector__field-wrap:first-child .tip-selector__radio-label {
  border-radius: 2px 0px 0px 2px;
}

.tip-selector__field-wrap:nth-child(2) .tip-selector__radio-label {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.tip-selector__field-wrap:last-child .tip-selector__radio-label {
  border-radius: 0px 2px 2px 0px;
}

.tip-selector__row:last-of-type .tip-selector__field-wrap:first-child {
  flex-basis: 33.33%;
}

.tip-selector__row:last-of-type .tip-selector__field-wrap:nth-child(2) {
  flex-basis: 66.67%;
}

.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
/*a11y updates*/
main a:hover, main a:focus, a.cc-link:hover,a.cc-link:focus {
  text-decoration: none;
}
a.cc-button:hover,a.cc-button:focus {
  text-decoration: underline;
}
#skiptomain a{
  position: absolute;
  top:0;
  left:-500em;
  color: white;
  background-color:var(--black);
  padding:10px;
}
#skiptomain a:hover, #skiptomain a:focus{
  position:relative;
  background-color: black;
  left: 0;text-decoration: underline;}
nav.citiesListContainer-1364 p {
  text-shadow: 2px 2px #044356
}
footer a:focus, footer a:hover {
  text-decoration: underline;
  opacity:1 !important;
  color: #231f20 !important;
}
.policyDetails-938 ul {
    list-style: initial !important;
    margin-left: 2rem;
    max-width: 90%;
}
.policyDetails-938 h2 {
    margin: initial;
    font-family: PitchSans!important;
    font-weight: 400!important;
    font-size: 0.875rem!important;
    letter-spacing: 0px!important;
    line-height: 25px !important;
    color: rgb(35, 31, 32);
}
@media (min-width: 52em){
 .policyDetails-938 h2 {
    font-family: PitchSans !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    letter-spacing: 0px !important;
    line-height: 25px !important;
}
.navContainer-833, .mobileMenu-831 {
    display: none !important;
}
}
ul {
    list-style: disc !important;
    margin-left: 2rem;
}
ul.slider-list {
  list-style: none !important;
}
ul.slider-list a:hover {
    text-decoration: underline;
}
ul.letter-list{
  list-style-type:upper-alpha
}
ul.lower-roman{
  list-style-type:lower-roman;
  margin:0;
}
a.cc-color-override-1523056044 .cc-btn {
  color: #413941 !important;
}
header a:hover span {
    text-decoration: underline;
}
a:focus{
  outline: 2px solid #000 !important;
  box-shadow: 0px 0px 0px 2px rgba(255,255,255,1) !important;
  outline-offset: 4px;
}
a:focus:not(:focus-visible) {
    outline: none !important;
    box-shadow: none !important;
}
.heroCopyContainer-1061 p,.heroCopyContainer-1064 p, .subheadingText p{
    font-family: PitchSans;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.09625rem;
    line-height: 1.75rem;
    text-shadow: 1px 1px 1px rgba(0,0,0,1);
}
.title-shadow h1 {
    text-shadow: 1px 1px black;
}
a.smartLink-779.navLink-780.dropdownLinkItem-778:hover, a.smartLink-779.navLink-780.dropdownLinkItem-778:focus {
    opacity: 1 !important;
    text-decoration: underline;
}
.menuLink-1018 p {
  text-decoration:none;
}
.menuLink-1018 a p {
  text-decoration: underline;
  color: #973a31;
}
.menuLink-1018 a:hover, .menuLink-1018 a:focus,.menuLink-1018 a p:hover {
  text-decoration: none !important;
}
.sideCartContainer929{display:none;}
.eyebrow{
      margin-bottom: 1.75rem;
    font-family: 'PitchSans';
    font-weight: bold;
    letter-spacing: 0.1418rem;
    line-height: 1.5rem;
}

button:hover {
    text-decoration: underline;
}
.originalStyle h2 {
    font-size: .875rem !important;
    font-weight: initial !important;
}
main a:hover {
    text-decoration: underline;
    color: initial;
}

span.light-175.navItem-618 {
    color: #231f20 !important;
}

.social-1033 > a > p {
    text-decoration: underline;
}

.social-1033 > a:hover > p, .social-1033 > a:hover, .social-1033 > a:focus > p{
    text-decoration: none;
}
